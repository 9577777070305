import React, { useContext, useEffect } from "react"

import {
  ArrowDown,
  ArrowRight,
  Check,
  ChevronDown,
  ChevronRight,
} from "react-feather"

import Energieausweis from "../../images/custom/Energieausweis.jpg"
import Curve1 from "../../svgs/shapes/curves/curve-1.svg"
import Curve4 from "../../svgs/shapes/curves/curve-4.svg"

import Search from "../../svgs/icons/duotone-icons/General/Search.svg"
import ActiveCall from "../../svgs/icons/duotone-icons/Communication/Active-call.svg"
import User from "../../svgs/icons/duotone-icons/General/User.svg"
import Bulb1 from "../../svgs/icons/duotone-icons/Home/bulb-1.svg"
import ClipboardCheck from "../../svgs/icons/duotone-icons/Communication/Clipboard-check.svg"
import Home from "../../svgs/icons/duotone-icons/Home/Home.svg"
import Marker1 from "../../svgs/icons/duotone-icons/Map/marker-1.svg"
import PenToolVector from "../../svgs/icons/duotone-icons/Design/Pen-tool-vector.svg"
import FileDone from "../../svgs/icons/duotone-icons/Files/File-done.svg"
import Accordion from "react-bootstrap/Accordion"
import { Link } from "gatsby"
import Image from "../../components/Image"
import imgLeft from "../../images/custom/immorating_cta_links.png"
import imgRight from "../../images/custom/immorating_cta_rechts.png"
const CTA2 = Loadable(() => import("../../components/CTA2"))
import BackgroundSection from "../../components/BackgroundSection"
const CTA = Loadable(() => import("../../components/CTA"))
import smoothScroll from "../../components/smoothScroll"
import AccordionHeaderCustomScroll from "../../components/AccordionHeaderCustomScroll"
import LayoutContext from "../../components/LayoutContext"
import HelmetHead from "../../components/HelmetHead"
import Loadable from "@loadable/component"

const ImmobilieVerkaufen = () => {
  //define if lead-generator is used in page!
  const canonicalPathname = `/de/immobilie-verkaufen/`
  const fixedPathname = `/de/immobilie-verkaufen/`

  const [layout, setLayout] = useContext(LayoutContext)

  useEffect(() => {
    setLayout(state => ({
      ...state,
      header: {
        color: "light",
      },
    }))
  }, [])

  return (
    <>
      <HelmetHead
        title="Immobilie verkaufen. Worauf Sie achten sollten. Fehler vermeiden."
        description="Möchten Sie Ihre Immobilie verkaufen? Starke Leistungen für Käufer und Verkäufer. Finden auch Sie den richtigen Makler für den Verkauf Ihrer Immobilie."
        keywords="Immobilienverkauf, Immobilie verkaufen, Haus verkaufen"
        links={[
          {
            rel: "canonical",
            href: `${process.env.GATSBY_SITE_URL}${canonicalPathname}`,
          },
        ]}
      />
      <BackgroundSection
        src="Lizensiert.undbearbeitetAdobeStock_55767668.jpg"
        className="bg-cover"
      >
        <div className="container">
          <div
            className="row align-items-end pb-10"
            style={{ minHeight: "90vh" }}
          >
            <div className="col-12">
              <h1 className="display-2 text-white fw-bold mb-0 text-center text-shadow-custom">
                Immobilie verkaufen
              </h1>
              <h2 className="display-4 text-white text-center fw-bold text-shadow-custom">
                Worauf Sie achten sollten
              </h2>
            </div>
          </div>
        </div>
      </BackgroundSection>

      <div className="position-relative">
        <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
          <Curve1 />
        </div>
      </div>

      <section className="py-7 py-md-9 border-bottom">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="scroll-beacon" id="immobilie-verkaufen" />
              <a
                href="#immobilie-verkaufen"
                className="btn btn-white btn-rounded-circle shadow mt-n11 mt-md-n13"
                onClick={smoothScroll}
              >
                <ArrowDown size={20} />
              </a>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-12 col-md-6 mb-7 mb-md-0">
              <p className="fs-lg text-muted">
                Eine Immobilie zu verkaufen ist eine komplexe Angelegenheit und
                mit keinem anderen Verkaufsprozess zu vergleichen. Nicht nur
                geht es dabei um enorm hohe Summen, auch die präzise
                Wertermittlung und Vermarktung einer Immobilie sind
                vielschichtige und teilweise heikle Unterfangen.
              </p>
              <p className="fs-lg text-muted">
                Doch mit den richtigen <b>Tipps und Informationen</b> gelingt
                auch Ihnen der erfolgreiche Verkauf Ihrer Immobilie. Wir bringen
                auf dieser Seite ein bisschen Licht ins Dunkel und teilen den
                Verkaufsprozess in <b>4 wesentliche Schritte</b> auf. Unsere
                Tools zur{" "}
                <u>
                  <Link to="/de/immobilienbewertung/">Immobilienbewertung</Link>
                </u>{" "}
                und zur{" "}
                <u>
                  <Link to="/de/">Maklersuche</Link>
                </u>{" "}
                stehen Ihnen dabei kostenlos zur Verfügung.
              </p>
              <p className="fs-lg text-muted">
                Zudem erfahren Sie die <b>7 häufigsten Fehler</b>, die Sie beim
                Verkauf Ihrer Immobilie leicht vermeiden können.
              </p>
            </div>
            <div className="col-12 col-md-6">
              <h3 className="fw-bold text-center">Inhalt</h3>

              <div className="card card-border border-success shadow-lg mb-5">
                <div className="card-body">
                  <div className="list-group list-group-flush">
                    <div className="list-group-item d-flex align-items-center">
                      <div className="me-auto">
                        <a
                          href="#schritt-1-unterlagen"
                          className="fw-bold mb-1"
                          onClick={smoothScroll}
                        >
                          Schritt 1: Unterlagen zusammenstellen
                        </a>
                      </div>
                      <div className="badge badge-rounded-circle bg-primary-desat-soft ms-4">
                        <ArrowRight size={15} />
                      </div>
                    </div>
                    <div className="list-group-item d-flex align-items-center">
                      <div className="me-auto">
                        <a
                          href="#schritt-2-immobilienbewertung"
                          className="fw-bold mb-1"
                          onClick={smoothScroll}
                        >
                          Schritt 2: Immobilienbewertung
                        </a>
                      </div>
                      <div className="badge badge-rounded-circle bg-primary-desat-soft ms-4">
                        <ArrowRight size={15} />
                      </div>
                    </div>
                    <div className="list-group-item d-flex align-items-center">
                      <div className="me-auto">
                        <a
                          href="#schritt-3-vermarktung"
                          className="fw-bold mb-1"
                          onClick={smoothScroll}
                        >
                          Schritt 3: Vermarktung
                        </a>
                      </div>
                      <div className="badge badge-rounded-circle bg-primary-desat-soft ms-4">
                        <ArrowRight size={15} />
                      </div>
                    </div>
                    <div className="list-group-item d-flex align-items-center">
                      <div className="me-auto">
                        <a
                          href="#schritt-4-verkaufen"
                          className="fw-bold mb-1"
                          onClick={smoothScroll}
                        >
                          Schritt 4: Verkaufen
                        </a>
                      </div>
                      <div className="badge badge-rounded-circle bg-primary-desat-soft ms-4">
                        <ArrowRight size={15} />
                      </div>
                    </div>
                    <div className="list-group-item d-flex align-items-center">
                      <div className="me-auto">
                        <a
                          href="#haeufige-fehler-immobilienverkauf"
                          className="fw-bold mb-1"
                          onClick={smoothScroll}
                        >
                          Die 7 häufigsten Fehler beim Verkauf einer Immobilie
                        </a>
                      </div>
                      <div className="badge badge-rounded-circle bg-primary-desat-soft ms-4">
                        <ArrowRight size={15} />
                      </div>
                    </div>
                    <div className="list-group-item d-flex align-items-center">
                      <div className="me-auto">
                        <a
                          href="#immobilienverkauf-corona"
                          className="fw-bold mb-1"
                          onClick={smoothScroll}
                        >
                          Immobilie verkaufen während Corona
                        </a>
                      </div>
                      <div className="badge badge-rounded-circle bg-primary-desat-soft ms-4">
                        <ArrowRight size={15} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="py-8 py-md-11 bg-gray-200 border-bottom"
        style={{ overflowX: "hidden" }}
      >
        <div className="container">
          <div className="scroll-beacon" id="schritt-1-unterlagen" />
          <h2 className="fw-bold">
            <span className="text-primary">Schritt 1:</span> Unterlagen
            zusammenstellen
          </h2>
          <p className="fs-lg text-muted">
            <b>Bevor Sie Ihr Objekt inserieren</b> und bewerben können, ist es
            zunächst notwendig, alle wichtigen Daten und Unterlagen zur
            Immobilie zusammenzustellen. Seien Sie sich im Klaren darüber, dass
            die Unvollständigkeit der Unterlagen ansonsten den weiteren
            Verkaufsprozess verzögern wird. Oder andersherum formuliert:
            Vollständige und aussagekräftige Unterlagen können potenzielle
            Käufer von der Qualität Ihrer Immobilie schnell überzeugen.
          </p>

          <p className="fs-lg text-muted mb-7 mb-md-9">
            <b>Doch welche Unterlagen sind das? Und wo bekommt man sie her?</b>{" "}
            Tatsächlich kann man da schnell den Überblick verlieren. Damit Ihnen
            das nicht passiert, nennen wir Ihnen hier die wichtigsten Unterlagen
            für den Immobilienverkauf und worauf es dabei jeweils ankommt:
          </p>

          <h3 className="fw-bold">Energieausweis</h3>

          <p className="fs-lg text-muted mb-7 mb-md-9">
            Der Energieausweis oder auch Energiepass, enthält Daten über die
            Energieeffizienz Ihrer Immobilie und ist in Deutschland mittlerweile
            gesetzlich vorgeschrieben. Ihre potenziellen Käufer können damit
            grob einschätzen, mit welchen Heizkosten bei einem Kauf zu rechnen
            ist. Die Größe Ihrer Immobilie und das Baujahr gibt Ihnen Auskunft
            darüber, ob Sie einen Verbrauchs- oder einen Bedarfsausweis
            benötigen:
          </p>

          <div className="card card-row shadow-light-lg mb-6 mb-md-8">
            <div className="row g-0">
              <div
                className="col-12 col-md-6 bg-cover card-img-start"
                style={{ backgroundImage: `url(${Energieausweis})` }}
              >
                <Image
                  src="Energieausweis.jpg"
                  alt="Energieausweis"
                  className="img-fluid d-md-none invisible"
                />

                <div className="shape shape-end shape-fluid-y svg-shim text-white d-none d-md-block">
                  <Curve4 />
                </div>
              </div>
              <div className="col-12 col-md-6 order-md-1">
                <div className="card-body py-5">
                  <h4>
                    <ChevronRight size={18} className="text-primary" />{" "}
                    <span className="font-bold">Verbrauchsausweis</span>
                    {"  "}
                    <span className="fs-sm text-primary d-block d-sm-inline d-md-block d-lg-inline">
                      (abhängig vom Verbrauch)
                    </span>
                  </h4>
                  <p className="text-muted mb-5">
                    Basiert auf den Heiz- und Energieverbrauchsdaten der letzten
                    drei Jahre. Kann online beantragt werden.
                  </p>
                  <h4 className="font-bold">
                    <ChevronRight size={18} className="text-primary" />{" "}
                    <span className="font-bold">Bedarfsausweis</span>
                    {"  "}
                    <span className="fs-sm text-primary d-block d-sm-inline d-md-block d-lg-inline">
                      (unabhängig vom Verbrauch)
                    </span>
                  </h4>
                  <p className="text-muted mb-0">
                    Ein Gutachter beurteilt die baulichen Gegebenheiten wie
                    Heizungsanlage, Qualität der Fenster oder Dämmung.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="row align-items-center py-6 py-md-8">
            <div className="col-12 col-md">
              <p className="fs-lg text-muted mb-0">
                Ihr Makler kann Ihnen die zuständigen Ansprechpartner nennen
                oder selbst den Energieausweis für Sie in Auftrag geben.
              </p>
            </div>
            <div className="col-12 col-md-auto mt-3 mt-md-0">
              <Link
                to="/de/maklerempfehlung/"
                className="btn btn-primary-soft lift"
              >
                Finden Sie jetzt den richtigen Makler{" "}
                <ArrowRight size={15} className="ms-auto" />
              </Link>
            </div>
          </div>

          <div className="row align-items-center py-6 py-md-8">
            <div className="col-12 col-md-6">
              <div
                className="mb-8 mb-md-0"
                data-sal="slide-right"
                data-sal-delay="200"
                data-sal-duration="700"
              >
                <Image
                  src="Fotolia_39512797_S.jpg"
                  imgStyle={{ objectFit: "contain" }}
                  className="screenshot img-fluid mw-110 float-end me-md-6 mb-6 mb-md-0 w-100 w-md-110"
                  alt="Grundbuchauszug - Immobilie Zeitung"
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <h3 className="fw-bold">Grundbuchauszug</h3>

              <p className="text-muted mb-6 mb-md-0">
                Der sogenannte Grundbuchauszug ist die Abschrift aller
                Eintragungen im{" "}
                <a
                  href="https://de.wikipedia.org/wiki/Grundbuch"
                  target="_blank"
                  className="text-muted"
                >
                  Grundbuch
                </a>{" "}
                und hält somit u.a. die Besitzverhältnisse einer Immobilie fest.
                Zudem gibt er Auskunft darüber, ob auf dem betreffenden
                Grundstück auch andere Rechte greifen (z.B. Wegerechte). Den
                Antrag für einen Grundbuchauszug stellen Sie bei Ihrem
                zuständigen Grundbuchamt. Häufig ist die Beantragung auch online
                möglich. Je nachdem ob Sie einen einfachen oder beglaubigten
                Grundbuchauszug benötigen, belaufen sich die Kosten zwischen 10
                und 20 EUR. Serviceorientierte Makler übernehmen die Besorgung
                des Grundbuchauszugs für Sie.
              </p>
            </div>
          </div>

          <div className="row align-items-center py-6 py-md-8">
            <div className="col-12 col-md-6">
              <h3 className="fw-bold">Fotos der Immobilie</h3>
              <p className="text-muted mb-6">
                Für ein ansprechendes Exposé Ihrer Immobilie, sind
                professionelle Fotos sehr entscheidend. Vielleicht haben Sie
                selbst schon einmal bei einer Wohnungssuche erlebt, welchen
                großen Einfluss Bilder (bewusst oder unterbewusst) auf uns
                haben. Vor allem, wenn sich ein Käufer viele Objekte
                hintereinander ansieht, sind es die Fotos, die Ihm im Gedächtnis
                bleiben. Es lohnt sich also durchaus, hier Geld in die Hand zu
                nehmen. Zudem sollten Sie den Bildern einen Grundriss Ihrer
                Immobilie beifügen.
              </p>
            </div>
            <div className="col-12 col-md-6">
              <div
                data-sal="slide-left"
                data-sal-delay="200"
                data-sal-duration="700"
              >
                <Image
                  src="AdobeStock_302487573-16-9.jpg"
                  imgStyle={{ objectFit: "contain" }}
                  className="screenshot img-fluid float-start ms-md-6 w-100 w-md-110"
                  alt="Haus mit Garten"
                />
              </div>
            </div>
          </div>

          <h3 className="fw-bold">Objekt-Details</h3>
          <p className="text-muted mb-6">
            Weitere Details zu Ihrem Objekt benötigen Sie nicht nur für ein
            vollständiges Exposé. Auch für die zuverlässige Wertermittlung Ihrer
            Immobilie (siehe{" "}
            <u>
              <a href="#schritt-2-immobilienbewertung">Schritt 2</a>
            </u>
            ) sind diese Daten von Nöten:
          </p>

          <div className="row align-items-center g-0">
            <div className="col-12 col-md-6">
              <div className="card rounded-3 shadow-lg mb-6 mb-md-0">
                <div className="card-body py-6 py-md-8">
                  <div className="row justify-content-center text-secondary">
                    <div className="col-12 col-xl-10">
                      <div className="d-flex">
                        <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                          <Check size={15} />
                        </div>
                        <p>Adress-Details </p>
                      </div>
                      <div className="d-flex">
                        <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                          <Check size={15} />
                        </div>
                        <p>Baujahr der Immobilie </p>
                      </div>
                      <div className="d-flex">
                        <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                          <Check size={15} />
                        </div>
                        <p>Zimmeranzahl</p>
                      </div>
                      <div className="d-flex">
                        <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                          <Check size={15} />
                        </div>
                        <p>Wohnfläche</p>
                      </div>
                      <div className="d-flex">
                        <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                          <Check size={15} />
                        </div>
                        <p>Grundstücksfläche (bei Häusern)</p>
                      </div>
                      <div className="d-flex">
                        <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                          <Check size={15} />
                        </div>
                        <p>Etage (bei Wohnungen)</p>
                      </div>
                      <div className="d-flex">
                        <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                          <Check size={15} />
                        </div>
                        <p>Angaben zu Balkon / Garten / Aufzug</p>
                      </div>
                      <div className="d-flex">
                        <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                          <Check size={15} />
                        </div>
                        <p>Parkmöglichkeiten</p>
                      </div>
                      <div className="d-flex">
                        <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                          <Check size={15} />
                        </div>
                        <p>Zustand von Mauerwerk und Dach</p>
                      </div>
                      <div className="d-flex">
                        <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                          <Check size={15} />
                        </div>
                        <p>
                          Angaben zur Dämmung, Fenstern, Bodenbelag, Heizung,
                          Sanitäranlagen
                        </p>
                      </div>
                      <div className="d-flex">
                        <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                          <Check size={15} />
                        </div>
                        <p>Besondere Ausstattungsmerkmale</p>
                      </div>
                      <div className="d-flex">
                        <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                          <Check size={15} />
                        </div>
                        <p>
                          Angaben zur Lage: Ausblick, Wohnumfeld, Besonnung,
                          Raumaufteilung
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 ms-md-n3">
              <div
                className="card rounded-3 shadow-lg mb-6 mb-md-0"
                style={{ zIndex: 1 }}
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-duration="700"
              >
                <div className="card-body py-6 py-md-8">
                  <div className="row justify-content-center">
                    <div className="col-12 col-xl-10">
                      <p className="mb-3">
                        <span className="badge rounded-pill bg-primary-soft">
                          <span className="h6 fw-bold text-uppercase">
                            Wichtige Unterlagen
                          </span>
                        </span>
                      </p>

                      <h3 className="text-muted mb-5">
                        Zum Verkauf einer Immobilie:
                      </h3>

                      <div className="d-flex text-secondary">
                        <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                          <Check size={15} />
                        </div>
                        <p>Energieausweis</p>
                      </div>
                      <div className="d-flex text-secondary">
                        <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                          <Check size={15} />
                        </div>
                        <p>Grundbuchauszug</p>
                      </div>
                      <div className="d-flex text-secondary">
                        <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                          <Check size={15} />
                        </div>
                        <p>Fotos der Immobilie</p>
                      </div>
                      <div className="d-flex text-secondary">
                        <div className="badge badge-rounded-circle bg-success-soft mt-1 me-4">
                          <Check size={15} />
                        </div>
                        <p>Objekt-Details</p>
                      </div>
                    </div>
                  </div>
                </div>

                <Link
                  to="/de/maklerempfehlung/"
                  className="card-btn btn btn-block btn-lg btn-secondary"
                >
                  Makler jetzt finden
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        className="py-8 py-md-11 border-bottom bg-between"
        style={{
          backgroundImage: `url(${imgLeft}), url(${imgRight})`,
          backgroundPosition: "left bottom, right bottom",
          backgroundSize: "auto 60%",
        }}
      >
        <div className="container">
          <div className="scroll-beacon" id="schritt-2-immobilienbewertung" />
          <h2 className="fw-bold">
            <span className="text-primary">Schritt 2:</span> Immobilienbewertung
          </h2>
          <p className="fs-lg text-muted mb-7 mb-md-9">
            Um nicht Gefahr zu laufen, den Wert Ihrer Immobilie zu über- oder
            unterschätzen, sollten Sie sich auf verschiedenen Wegen über den
            tatsächlichen Immobilienwert versichern. Wir empfehlen Ihnen dafür
            folgendes Vorgehen:
          </p>
          <div className="card shadow-lg">
            <div className="row align-items-stretch justify-content-center py-8">
              <div className="col-11 col-md-4 p-md-0">
                <h4>Kostenlose Immobilienbewertung</h4>

                <p className="text-muted">
                  Eine kostenlose Immobilien­bewertung bietet Ihnen die
                  Möglichkeit, sich zunächst <b>unverbindlich und schnell</b>{" "}
                  einen Überblick über das Wertniveau Ihrer Immobilie zu
                  verschaffen. Neben dem Immobilienwert enthalten die Ergebnisse
                  außerdem eine hochwertige Marktanalyse und wertvolle
                  Informationen zu Ihren angegebenen Daten. Damit schaffen Sie
                  die perfekte <b>Grundlage für Verhandlungs­gespräche</b> mit
                  potenziellen Käufern, Immobilienmaklern, Banken oder
                  Versicherungen.
                </p>
              </div>
              <div
                className="col-12 col-md-1 mt-n4 d-none d-md-block"
                style={{ borderRight: "1px solid #009cdf" }}
              />
              <div className="col-11 col-md-4 p-md-0 offset-md-1">
                <h4>Gutachten vor Ort</h4>

                <p className="text-muted">
                  Um sich über den Wert Ihrer Immobilie abzusichern, empfehlen
                  wir Ihnen, ein <b>zusätzliches Gutachten vor Ort</b> machen zu
                  lassen. Zwar basieren die Ergebnisse unserer kostenlosen
                  Immobilien­bewertung auf einem deutschlandweit anerkannten
                  Verfahren und versprechen damit eine sehr hohe Genauigkeit.
                  Eine Wert­ermittlung kann jedoch schlichtweg nicht mit den
                  Ergebnissen eines erfahrenen Gutachters bzw.{" "}
                  <u>
                    <Link to="/de/">Maklers</Link>
                  </u>{" "}
                  vor Ort mithalten.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-8 py-md-11 bg-gray-200 border-bottom">
        <div className="container">
          <div className="scroll-beacon" id="schritt-3-vermarktung" />
          <h2 className="fw-bold">
            <span className="text-primary">Schritt 3:</span> Vermarktung
          </h2>

          <p className="fs-lg text-muted mb-7 mb-md-9">
            Spätestens hier stellt sich für Sie die Frage:{" "}
            <b>Makler – Ja oder Nein?</b> Was in Ihrem spezifischen Fall
            sinnvoll ist, müssen Sie letztendlich selbst entscheiden. Wir
            möchten Ihnen jedoch unbedingt ans Herz legen, sich vor allem in
            Sachen „Vermarktung“ vom Profi helfen zu lassen.{" "}
            <b>Folgende Aufgaben übernimmt ein Makler für Sie:</b>
          </p>

          <div className="row">
            <div className="col-12 col-md-6 mb-4">
              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">
                  Erstellung eines aussagekräftigen und attraktiven Exposés
                </p>
              </div>
              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">
                  Fokus auf eine spezifische Zielgruppe
                </p>
              </div>
              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">
                  Entwicklung einer Marketingstrategie
                </p>
              </div>
              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">
                  Festlegung und Bespielung sämtlicher Marketing-Kanäle (digital
                  und analog)
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 mb-4">
              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">
                  Qualitätssicherung und Anpassung der Vermarktungs-Strategien
                </p>
              </div>
              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">Komplettes Besichtigungsmanagement</p>
              </div>
              <div className="d-flex">
                <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                  <Check size={15} />
                </div>
                <p className="text-muted">
                  Bonitätsprüfung und Selektion der Bewerber
                </p>
              </div>
            </div>
          </div>

          <p className="fs-sm text-center text-gray-500">
            (Übrigens: Ein Makler unterstützt Sie auch in sämtlichen Belangen
            der Schritte 1 und 2.)
          </p>

          <p className="fs-lg text-muted mb-7 mb-md-9">
            Zudem verfügt ein Makler in aller Regel über eine sehr{" "}
            <b>gute Marktkenntnis und einen Marktüberblick</b> in Ihrer Region.
            Darüber hinaus profitieren Sie von seinem breiten Kundenstamm. Alles
            in allem ersparen Sie sich mit einem Makler eine Menge Zeit, Nerven
            und in der Regel auch Geld.
          </p>

          <CTA fullWidth={true}>
            <h3 className="font-bold">Ein Makler lohnt sich</h3>
            <div className="d-flex">
              <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                <Check size={15} />
              </div>
              <p className="text-muted">
                Eine professionelle Vermarktung erfordert viel Zeit und
                Marktkenntnis
              </p>
            </div>
            <div className="d-flex">
              <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                <Check size={15} />
              </div>
              <p className="text-muted">
                Die Vermarktung sollte auf mehreren Kanälen erfolgen
              </p>
            </div>
            <div className="d-flex">
              <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                <Check size={15} />
              </div>
              <p className="text-muted">
                Ein hochwertiges Exposé ist essenziell für den erfolgreichen
                Verkauf
              </p>
            </div>
          </CTA>
        </div>
      </section>

      <section className="py-8 py-md-11 border-bottom" id="schritt-4-verkaufen">
        <div className="container">
          <div className="scroll-beacon" id="schritt-4-verkaufen" />
          <h2>
            <span className="text-primary">Schritt 4:</span> Verkaufen
          </h2>
          <p className="fs-lg text-muted mb-6">
            Der vielleicht schwierigste Teil des ganzen Prozesses, denn{" "}
            <b>Verkaufen will gelernt sein!</b> Auch hier empfehlen wir Ihnen
            die Zusammenarbeit mit dem Profi. Dennoch möchten wir Ihnen hier
            einige Tipps mit auf den Weg geben, die für Käuferkontakt und
            Verkaufsgespräch besonders wichtig sind:
          </p>

          <div className="row pt-5">
            <div className="col-12 col-md-6 d-flex">
              <div
                className="icon text-primary"
                data-sal="slide-down"
                data-sal-delay="200"
                data-sal-duration="700"
              >
                <Search />
              </div>
              <div className="ms-5">
                <h4 className="mb-1">Makellose Besichtigung</h4>
                <p className="text-muted mb-6">
                  Sorgen Sie dafür, dass sich die Wohnung oder das Haus für die
                  Besichtigung in einem einwandfreien Zustand befindet. Geben
                  Sie dem Interessenten keinen Angriffspunkt, denn der erste
                  Eindruck zählt.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 d-flex">
              <div
                className="icon text-primary-desat"
                data-sal="slide-down"
                data-sal-delay="200"
                data-sal-duration="700"
              >
                <Bulb1 />
              </div>
              <div className="ms-5">
                <h4 className="mb-1">Gute Vorbereitung</h4>
                <p className="text-muted mb-6">
                  Seien Sie für jeden Besichtigungstermin perfekt vorbereitet
                  und haben Sie etwaige Unterlagen auf Wunsch immer griffbereit.
                  Informieren Sie sich vorab auch umfassend über alle Daten der
                  Immobilie, sodass Sie die Fragen des Interessenten stets
                  souverän beantworten können.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 d-flex">
              <div
                className="icon text-primary"
                data-sal="slide-down"
                data-sal-delay="200"
                data-sal-duration="700"
              >
                <User />
              </div>
              <div className="ms-5">
                <h4 className="mb-1">Professionelles Auftreten</h4>
                <p className="text-muted mb-6">
                  Selbstverständlich sollte zunächst ein freundliches und
                  zuvorkommendes Verhalten sein. Daneben ist jedoch auch ein
                  selbstsicheres und selbstbewusstes Auftreten sehr zuträglich
                  für einen erfolgreichen Verkauf.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 d-flex">
              <div
                className="icon text-primary-desat"
                data-sal="slide-down"
                data-sal-delay="200"
                data-sal-duration="700"
              >
                <ActiveCall />
              </div>
              <div className="ms-5">
                <h4 className="mb-1">Ehrliche Kommunikation</h4>
                <p className="text-muted mb-6">
                  Unter keinen Umständen sollten Sie Mängel oder Makel des
                  Objekts dem Interessenten gegenüber verschweigen. Zum einen
                  bauen Sie so ein verstärktes Vertrauen auf. Zum anderen
                  vermeiden Sie dadurch spätere rechtliche Konsequenzen (
                  <a
                    href="https://www.gesetze-im-internet.de/bgb/__123.html"
                    target="_blank"
                    className="text-muted"
                  >
                    arglistige Täuschung
                  </a>
                  ).
                </p>
              </div>
            </div>
            <div className="col-12 d-flex">
              <div
                className="icon text-primary"
                data-sal="slide-down"
                data-sal-delay="200"
                data-sal-duration="700"
              >
                <ClipboardCheck />
              </div>
              <div className="ms-5">
                <h4 className="mb-1">Notarielle Vertragsabwicklung</h4>
                <p className="text-muted mb-6">
                  Ein „unparteiischer“ Notar ist für den Immobilienverkauf
                  üblich. Er klärt Sie und Ihren Käufer über die Feinheiten
                  Ihres Kaufvertrags auf und tätigt den Grundbucheintrag. Für
                  Vertragsdetails, wie bspw. (mündliche) Absprachen sind Sie
                  jedoch selbst verantwortlich. Berücksichtigen Sie hier v.a.
                  rechtliche Vorgaben.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <BackgroundSection
        src="ImmobilieGartenSW.jpg"
        className="py-8 py-md-11"
        overlayColor="linear-gradient(rgba(11, 33, 76, .95), rgba(11, 33, 76, .95))"
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 text-center">
              <span className="badge rounded-pill bg-primary-desat-soft mb-3">
                <span className="h6 text-uppercase">Extra Tipp</span>
              </span>

              <h3 className="display-4 text-white mb-5">
                Wichtige Hinweise für den Verkauf
              </h3>

              <div className="row justify-content-center">
                <div className="col-9 text-start">
                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                      <Check size={15} />
                    </div>
                    <p className="text-white-75">
                      Eine professionelle Vermarktung erfordert viel Zeit und
                      Marktkenntnis
                    </p>
                  </div>
                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                      <Check size={15} />
                    </div>
                    <p className="text-white-75">
                      Die Vermarktung sollte auf mehreren Kanälen erfolgen
                    </p>
                  </div>
                  <div className="d-flex">
                    <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                      <Check size={15} />
                    </div>
                    <p className="text-white-75">
                      Ein hochwertiges Exposé ist essenziell für den
                      erfolgreichen Verkauf
                    </p>
                  </div>
                </div>
              </div>

              <p className="fs-lg text-white-75 mb-6 mb-md-8">
                Verkaufen will gelernt sein: Ein Makler bringt die nötige
                Expertise mit!
              </p>

              <div
                data-sal="slide-up"
                data-sal-delay="200"
                data-sal-duration="700"
              >
                <Link
                  to="/de/maklerempfehlung/"
                  className="btn btn-primary-desat lift"
                >
                  Finden Sie jetzt den richtigen Makler{" "}
                  <ArrowRight className="ms-2" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </BackgroundSection>

      <div className="position-relative">
        <div className="shape shape-bottom shape-fluid-x svg-shim text-gray-200">
          <Curve1 />
        </div>
      </div>

      <section className="py-8 py-md-11 border-bottom">
        <div className="container">
          <div
            className="scroll-beacon"
            id="haeufige-fehler-immobilienverkauf"
          />
          <h2 className="text-center">
            Die 7 häufigsten Fehler beim Verkauf einer Immobilie
          </h2>

          <Accordion
            defaultActiveKey="vorschnelles-handeln"
            className="card shadow-light-lg mt-7 mt-md-9 mb-5 mb-md-6"
          >
            <Accordion.Item eventKey="vorschnelles-handeln">
              <AccordionHeaderCustomScroll anchor="vorschnelles-handeln">
                1. Vorschnelles Handeln
              </AccordionHeaderCustomScroll>
              <Accordion.Body>
                <p className="text-muted">
                  Einige Eigentümer, nehmen den Verkauf Ihrer Immobilie zunächst
                  auf die leichte Schulter und schalten vorschnell eine{" "}
                  <b>Anzeige auf gut Glück</b>. In den aller seltensten Fällen
                  führt das zu einem erfolgreichen Verkauf. Zudem führt das
                  vorschnelle Handeln häufig zu Verkäufen, deutlich unter dem
                  eigentlichen Wert der Immobilie. Damit Sie einen finanziellen
                  Verlust vermeiden, halten Sie sich am besten an unseren
                  4-Schritte-Plan und treffen Sie alle nötigen Vorkehrungen mit
                  großer Sorgfalt.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="schaetzung-immobilienwert">
              <AccordionHeaderCustomScroll anchor="schaetzung-immobilienwert">
                2. Unter- oder Überschätzung des Immobilienwerts
              </AccordionHeaderCustomScroll>
              <Accordion.Body>
                <p className="text-muted">
                  <b>Überschätzen des Immobilienwerts</b>: Zu hohe Preise können
                  potenzielle Käufer abschrecken und Sie langfristig eine Menge
                  Zeit beim Verkauf Ihrer Immobilie kosten.
                </p>
                <p className="text-muted">
                  <b>Unterschätzen des Immobilienwerts</b>: Häufig unterschätzen
                  Verkäufer den Wert Ihrer Immobilie in einem Ausmaß, das die
                  Kosten eines Maklers um ein Vielfaches überschreiten würde.
                  Sie verlieren in diesem Fall große Summen.
                </p>
                <p className="text-muted">
                  Wir empfehlen Ihnen, hier nichts dem Zufall zu überlassen.
                  Nutzen Sie in einem ersten Schritt unsere{" "}
                  <u>
                    <a href="#schritt-2-immobilienbewertung">
                      kostenlose Immobilienbewertung
                    </a>
                  </u>
                  , um sich unverbindlich einen ersten Eindruck zu verschaffen.
                  Engagieren Sie im nächsten Schritt einen{" "}
                  <u>
                    <Link to="/de/maklerempfehlung/">Profi</Link>
                  </u>
                  . Er wird Sie vor teuren Fehlern bewahren.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="mangelhaftes-expose">
              <AccordionHeaderCustomScroll anchor="mangelhaftes-expose">
                3. Mangelhaftes Exposé
              </AccordionHeaderCustomScroll>
              <Accordion.Body>
                <p className="text-muted">
                  Ein ansprechendes und <b>professionell wirkendes Exposé</b> zu
                  erstellen ist nicht einfach und in der Regel sehr
                  zeitintensiv. Diese Zeit sollten Sie sich jedoch unbedingt
                  nehmen. Denn nur mit einem guten Exposé können Sie das
                  maximale Potenzial aus Ihrem Objekt herausholen! Der
                  erfolgreiche und schnelle Verkauf Ihrer Immobilie steht und
                  fällt mit einem guten Exposé (attraktive Bilder, ansprechender
                  Text, ganzheitliche Informationen, etc.).
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="vorteile-gegen-gutacher">
              <AccordionHeaderCustomScroll anchor="vorteile-gegen-gutacher">
                4. Fehlende Vermarktung der Immobilie
              </AccordionHeaderCustomScroll>
              <Accordion.Body>
                <p className="text-muted">
                  Die Vermarktung Ihrer Immobilie sollte so vielschichtig wie
                  möglich erfolgen. Sprich: Um eine Auswahl an potenziellen
                  Käufern zu finden reicht es nicht aus, ein Objekt lediglich
                  auf einer einzigen Immobilienplattform zu inserieren. Viel
                  mehr empfiehlt es sich, sowohl{" "}
                  <b>
                    digital als auch analog verschiedenste Marketing-Maßnahmen
                  </b>{" "}
                  zu ergreifen.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="kaufgespraech-und-verhandlung">
              <AccordionHeaderCustomScroll anchor="kaufgespraech-und-verhandlung">
                5. Unzureichende Kaufgespräche- und Verhandlungen
              </AccordionHeaderCustomScroll>
              <Accordion.Body>
                <p className="text-muted">
                  Eine gute und erfolgsversprechende Kommunikation mit dem
                  potenziellen Käufer setzt sich aus den{" "}
                  <b>folgenden Faktoren</b> zusammen:
                </p>
                <div className="row">
                  <div className="col-md-6">
                    <div className="d-flex">
                      <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                        <Check size={15} />
                      </div>
                      <p className="text-muted">
                        Sehr gute Erreichbarkeit per Telefon/E-Mail/Fax
                      </p>
                    </div>
                    <div className="d-flex">
                      <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                        <Check size={15} />
                      </div>
                      <p className="text-muted">
                        Transparenz dem Käufer gegenüber (keine falschen
                        Angaben!)
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex">
                      <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                        <Check size={15} />
                      </div>
                      <p className="text-muted">
                        Stets gute Vorbereitung auf Gesprächssituationen (alle
                        nötigen Daten parat haben)
                      </p>
                    </div>
                    <div className="d-flex">
                      <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                        <Check size={15} />
                      </div>
                      <p className="text-muted">
                        Verkaufsgeschick bei Preisverhandlungen
                      </p>
                    </div>
                  </div>
                </div>
                <p className="text-muted">
                  Sind diese Faktoren nicht in einem ausreichenden Maß erfüllt,
                  so werden Sie Probleme damit haben, Interessenten zu halten
                  und einen angemessenen Preis für Ihre Immobilie zu verhandeln.
                </p>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="berechnung-immobilienwert">
              <AccordionHeaderCustomScroll anchor="berechnung-immobilienwert">
                6. Sparen an den falschen Stellen
              </AccordionHeaderCustomScroll>
              <Accordion.Body>
                <p className="text-muted">
                  Beim Stichwort „Immobilienmakler“ sind viele Eigentümer
                  zunächst abgeschreckt, da Sie damit primär hohe Kosten
                  verbinden. Doch diese Rechnung ist nicht zu Ende gedacht. Ein
                  Immobilienmakler bewahrt Sie nicht nur vor teuren und
                  zeitintensiven Fehlern (siehe Punkte 1-5), sondern holt in
                  aller Regel sogar noch einiges aus dem Verkaufspreis heraus.
                  Unterm Strich stehen Sie damit finanziell sehr viel besser da.
                </p>
                <h4>Alle Vorteile eines Immobilienmaklers auf einen Blick:</h4>
                <div className="row">
                  <div className="col-md-6">
                    <div className="d-flex">
                      <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                        <Check size={15} />
                      </div>
                      <p className="text-muted">
                        Sie sparen Geld, Zeit und Nerven
                      </p>
                    </div>
                    <div className="d-flex">
                      <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                        <Check size={15} />
                      </div>
                      <p className="text-muted">
                        Sie vermeiden entscheidende Fehler
                      </p>
                    </div>
                    <div className="d-flex">
                      <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                        <Check size={15} />
                      </div>
                      <p className="text-muted">
                        Sie finden schneller einen Käufer
                      </p>
                    </div>
                    <div className="d-flex">
                      <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                        <Check size={15} />
                      </div>
                      <p className="text-muted">
                        Sie profitieren von der Marktkenntnis und dem
                        Kundenstamm des Maklers
                      </p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="d-flex">
                      <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                        <Check size={15} />
                      </div>
                      <p className="text-muted">
                        Ihre rechtliche Sicherheit ist gewährleistet
                      </p>
                    </div>
                    <div className="d-flex">
                      <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                        <Check size={15} />
                      </div>
                      <p className="text-muted">
                        Sie müssen keine Verkaufsgespräche führen
                      </p>
                    </div>
                    <div className="d-flex">
                      <div className="badge badge-rounded-circle bg-success mt-1 me-4">
                        <Check size={15} />
                      </div>
                      <p className="text-muted">
                        Sie können die Kosten komplett auf den Käufer umlenken
                      </p>
                    </div>
                  </div>
                </div>
                <Link
                  to="/de/maklerempfehlung/"
                  className="fs-sm fw-bold text-decoration-none"
                >
                  Finden Sie jetzt den richtigen Makler{" "}
                  <ArrowRight className="ms-3" />
                </Link>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="vertrag">
              <AccordionHeaderCustomScroll anchor="vertrag">
                7. Nachlässigkeit beim Vertrag
              </AccordionHeaderCustomScroll>
              <Accordion.Body>
                <p className="text-muted">
                  Die Beglaubigung eines Kaufvertrags wird in Deutschland von
                  einem Notar vorgenommen. Verwechseln Sie jedoch nicht, welche
                  Funktion der Notar hier einnimmt: Er ist lediglich für die
                  Vertragsgestaltung und die Änderungen im Grundbuch zuständig.
                  Was die genauen Vertragsdetails und etwaige mündliche
                  Absprachen betrifft, so sind die{" "}
                  <b>Vertragsparteien selbst in der Verantwortung</b>. Hier gilt
                  es vor allem, den rechtlichen Rahmen einzuhalten. Überlassen
                  Sie den Kaufvertrag in keinem Fall komplett dem Notar.
                </p>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </section>

      <CTA2>
        <blockquote className="blockquote">
          <p className="h2 text-white">
            Sie möchten Ihre Immobilie verkaufen?
            <br />
            Geben Sie Ihre Immobilie in gute Hände.
          </p>
        </blockquote>
      </CTA2>

      <section className="py-8 py-md-11 bg-gray-200 border-bottom">
        <div className="container">
          <div className="scroll-beacon" id="immobilienverkauf-corona" />
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-8 text-center mb-9">
              <h2 className="fw-bold">
                Immobilie verkaufen während Corona: <br />
                <span className="text-primary">Aktuelle Hinweise</span>
              </h2>

              <p className="fs-lg text-muted">
                Die Covid-19-Pandemie stellt uns in vielen Lebensbereichen vor
                neue Herausforderungen. So auch beim Verkauf einer Immobilie.
                Nach wie vor ist der Verkauf zwar möglich, jedoch nur unter
                besonderen Umständen. Nachfolgend möchten wir Sie über die
                besonderen Gegebenheiten aufklären:
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6">
              <div
                className="card card-border shadow-light-lg mb-6 mb-md-8 lift lift-lg"
                style={{ borderTopColor: "#4ac25a" }}
              >
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between mb-2 ">
                    <div>
                      <h4 className="fw-bold mb-0">
                        Immobilienbewertungen und kontaktlos vor Ort:
                      </h4>
                    </div>
                    <div className="icon text-primary ms-5">
                      <FileDone />
                    </div>
                  </div>
                  <p className="text-muted">
                    Eine Immobilienbewertung durch den Makler kann kontaktlos,
                    also ohne Ihre Anwesenheit erfolgen. Wir empfehlen Ihnen
                    daher aktuell mehr denn je, sich vorab auch ein eigenes Bild
                    über den Wert Ihrer Immobilie zu machen. Nutzen Sie hierfür
                    unseren{" "}
                    <u>
                      <Link to="/de/immobilienbewertung/">
                        kostenlosen Immobilienbewerter
                      </Link>
                    </u>
                    .
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div
                className="card card-border shadow-light-lg mb-6 mb-md-8 lift lift-lg"
                style={{ borderTopColor: "#153f91" }}
              >
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between mb-2 ">
                    <div>
                      <h4 className="fw-bold mb-0">
                        Vollmacht für die notarielle Beurkundung des
                        Kaufvertrags
                      </h4>
                    </div>
                    <div className="icon text-primary ms-5">
                      <PenToolVector />
                    </div>
                  </div>
                  <p className="text-muted">
                    V.a. wenn Sie zu einer der Risikogruppen zählen sollten, ist
                    dies eine gute Option für Sie. Eine weitere Person kann
                    bevollmächtigt werden, an Ihrer Stelle den Kaufvertrag zu
                    unterzeichnen. Ebenso können Sie der Belehrung per
                    Video-Telefonie beiwohnen und eingreifen.
                  </p>
                  <br className="d-none d-lg-inline d-xl-none" />
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div
                className="card card-border shadow-light-lg mb-6 mb-md-8 lift lift-lg"
                style={{ borderTopColor: "#ff0000" }}
              >
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between mb-2 ">
                    <div>
                      <h4 className="fw-bold mb-0">
                        Besichtigungen vor Ort maximal zu zweit
                      </h4>
                    </div>
                    <div className="icon text-primary ms-5">
                      <Marker1 />
                    </div>
                  </div>
                  <p className="text-muted">
                    Äußert ein potenzieller Käufer sein ernsthaftes Interesse am
                    Kauf, so ist auch die Besichtigung vor Ort unter besonderen
                    Auflagen möglich: Maximal zu zweit (Makler und
                    Kaufinteressent) kann eine Immobilie besichtigt werden.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div
                className="card card-border shadow-light-lg mb-6 mb-md-8 lift lift-lg"
                style={{ borderTopColor: "#ffb900" }}
              >
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between mb-2 ">
                    <div>
                      <h4 className="fw-bold mb-0">„Geisterbesichtigungen“</h4>
                    </div>
                    <div className="icon text-primary ms-5">
                      <Home />
                    </div>
                  </div>
                  <p className="text-muted">
                    Besichtigungen finden im ersten Schritt nur online statt.
                    Zum Einsatz kommen 360-Grad-Exposés und digitale Rundgang
                    bzw. Geisterbesichtigungen per Video-Call.
                  </p>
                  <br className="d-none d-lg-inline" />
                  <br className="d-none d-xl-inline" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="position-relative">
        <div className="shape shape-bottom shape-fluid-x svg-shim text-white">
          <Curve1 />
        </div>
      </div>
    </>
  )
}

export default ImmobilieVerkaufen
